import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/messaging";
import Vue from "vue";
const apiUrl =
  process.env.VUE_APP_API + "notifications/addnotificationsusersstudents";
import axios from "axios";
class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);

      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          icon: "./notlogo.png",
        };
        Vue.notify({
          group: "foo",
          title: notificationTitle,
          text: notificationOptions.body,
        });
        self.registration.showNotification(
          notificationTitle,
          notificationOptions
        );
      });

      messaging
        .getToken({
          vapidKey:
            process.env.VUE_APP_VADKEY,
        })
        .then((currentToken) => {
          if (currentToken) {
            let Student = JSON.parse(localStorage.getItem("user"));
            if (Student != null) {
              let info = {
                token: currentToken,
                name: Student.username,
                type: "student",
                uid: Student.id,
                schoolid: Student.schoolId,
                schoolname: Student.schoolname,
              };
              axios
                .post(apiUrl, info)
                .then((res) => {
                  console.log(res);
                })
                .catch((ex) => {
                  console.log(ex);
                });
            }
          } else {
            // Show permission request UI
            Notification.requestPermission()
              .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
                // ...
              });

            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    }
  }

  /**
   * Registers the user with given details
   */
  GetMessagesFB = () => {
    return firebase.messaging();
  };

  _handleError(error) {
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
