<template>
  <div id="app">
    <notifications group="foo" />
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import {
  ClassesDaysMethods,SessionsDatesMethods,studentClassMethods,examsMethods,classesexamsMethods,ResultsMethods,paymentnotificationMethods,classesMethods,schoolsMethods,StudentPaymentsMethods
} from "@/state/helpers";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  mounted(){
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
      var retVal = confirm("Ajouter Lilas Langues à l'écran d'accueil ?");
      if (retVal == true) {
        this.installPWA();
      } else {
        this.dismissPrompt();
      }
    });
  },
  created(){
     let Student = JSON.parse(localStorage.getItem("user"));
     if(Student != null){
      this.getStudentClass({ id:Student.id})
      this.getSessionsDates({ id:Student.schoolId})
      this.getClassesDays({ id:Student.schoolId})
      this.getExam({ id:Student.schoolId})
      this.getClassesExam({ id:Student.schoolId})
      this.getResults({ id:Student.id})
      this.getPaymentNot({ id:Student.id})
      this.getClasses({ id:Student.schoolId})
      this.getSchools({ id:Student.schoolId})
      this.getStudentPayments({ id:Student.id})      
     }
   
  },
  methods:{
    ...studentClassMethods,
    ...ClassesDaysMethods,
    ...SessionsDatesMethods,
    ...examsMethods,
    ...classesexamsMethods,
    ...ResultsMethods,
    ...paymentnotificationMethods,
    ...classesMethods,
    ...schoolsMethods,
    ...StudentPaymentsMethods,
    dismissPrompt() {
      this.shown = false;
    },
    installPWA() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then(choice => {
        this.dismissPrompt(); // Hide the prompt once the user's clicked
        if (choice.outcome === "accepted") {
          // Do something additional if the user chose to install
        } else {
          this.shown = false;
        }
      });
    }
  }
};
</script>